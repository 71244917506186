import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import VideoSection from '../components/VideoSection/VideoSection'
import BenefitsSection from '../components/Benefits/BenefitsSection'
import KnowMenuSection from '../components/KnowMenuSection/KnowMenuSection'
import RockSection from '../components/RockSection/RockSection'
import OurHistory from '../components/OurHistory/OurHistory'
import GallerySection from '../components/GallerySection/GallerySection'
import { graphql } from 'gatsby'
import EventModal from '../components/EventModal/EventModal'

export const HomePageTemplate = ({
  mainBanner,
  menuSection,
  ourHistorySection,
  rockAndSpotifySection,
  benefitsSection,
  whatWeServeSection
}) => {
  return (
    <div className="bg-dark">
      <EventModal />
      <VideoSection
        title1={mainBanner.title1}
        title2={mainBanner.title2}
        title3={mainBanner.title3}
        description={mainBanner.description}
        button={mainBanner.button}
      />

      {menuSection.isActive && (
        <KnowMenuSection
          title={menuSection.title}
          description={menuSection.description}
          button={menuSection.button}
          image={menuSection.backgroundImage.childImageSharp.fluid.src}
        />
      )}

      {ourHistorySection.isActive && (
        <OurHistory
          title={ourHistorySection.title}
          description={ourHistorySection.description}
          button={ourHistorySection.button}
        />
      )}

      {rockAndSpotifySection.isActive && (
        <RockSection
          cards={rockAndSpotifySection.cards}
          imageSrc={
            rockAndSpotifySection.backgroundImage.childImageSharp.fluid.src
          }
        />
      )}

      {benefitsSection.isActive && (
        <BenefitsSection cards={benefitsSection.cards} />
      )}

      {whatWeServeSection.isActive && (
        <GallerySection
          title={whatWeServeSection.title}
          photos={whatWeServeSection.images.map(photo => {
            return {
              src: photo.image.childImageSharp.fluid.src,
              width: photo.image.childImageSharp.fluid.aspectRatio,
              height: 1,
              alt: photo.title
            }
          })}
        />
      )}
    </div>
  )
}

const HomePage = ({ data: { page } }) => {
  const {
    mainBanner,
    menuSection,
    ourHistorySection,
    rockAndSpotifySection,
    benefitsSection,
    whatWeServeSection
  } = page.frontmatter
  return (
    <Layout>
      <Helmet>
        <title>Início</title>
      </Helmet>
      <HomePageTemplate
        mainBanner={mainBanner}
        menuSection={menuSection}
        ourHistorySection={ourHistorySection}
        rockAndSpotifySection={rockAndSpotifySection}
        benefitsSection={benefitsSection}
        whatWeServeSection={whatWeServeSection}
      />
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
        mainBanner {
          title1
          title2
          title3
          description
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          button {
            isActive
            title
            isInternal
            link
          }
        }
        menuSection {
          isActive
          title
          description
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          button {
            title
            isInternal
            link
          }
        }
        ourHistorySection {
          isActive
          title
          description
          button {
            title
            isInternal
            link
          }
        }
        rockAndSpotifySection {
          isActive
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          cards {
            isActive
            title
            description
            cardImage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            button {
              title
              isInternal
              link
            }
          }
        }
        benefitsSection {
          isActive
          cards {
            isActive
            title
            description
            cta {
              title
              to
            }
            cardImage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        whatWeServeSection {
          isActive
          title
          images {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
