import React from 'react'

import { FaArrowRight } from 'react-icons/fa'

const RockItem = ({ title, description, image, buttomText, button }) => {
  return (
    <div className="flex-1 flex flex-col justify-between bg-black bg-opacity-75 drop-shadow-lg rounded-lg my-4 mx-0 md:m-8 p-5 self-stretch">
      <p className="text-lg text-primary text-center md:text-left mb-5">
        {title}
      </p>
      <div className="flex flex-col md:flex-row items-center">
        <img
          src={image}
          alt={title}
          className="flex-1 w-40 mb-5 md:mr-5 md:mb-0 rounded-lg"
        />
        <p className="mreaves mb-4 sm:mb-0 flex-1 text-base text-white">
          {description}
        </p>
      </div>
      <a
        href={button.link}
        target="_blank"
        className="text-base text-primary justify-end flex hover:underline"
        rel="noreferrer"
      >
        {button.title}
        <FaArrowRight className="h-5 w-5 ml-2" />
      </a>
    </div>
  )
}
export default RockItem
