import React from 'react'

import Div from '../Motion/Div/Div'
import RockItem from './RockItem'

const RockSection = ({ cards, imageSrc }) => {
  return (
    <section
      className="bg-dark banner"
      style={{
        background: `linear-gradient(180deg,rgba(0,0,0,1) 0,rgba(0,0,0,.6)) ,url(${imageSrc})`
      }}
    >
      <Div className="flex flex-col max-w-screen-xl mx-auto lg:flex-row py-10 px-5 items-center">
        {cards.map((card, index) => {
          {
            if (card.isActive)
              return (
                <RockItem
                  key={index}
                  title={card.title}
                  description={card.description}
                  button={card.button}
                  image={card.cardImage.childImageSharp.fluid.src}
                />
              )
          }
        })}
        {/* <RockItem
          title="Galeria do Rock"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat."
          image="https://www.tenhomaisdiscosqueamigos.com/wp-content/uploads/2019/06/idles-banda-barcelona.jpg"
          buttomText="Conhecer"
          buttomLink="https://br.lipsum.com/"
        />
        <RockItem
          title="Playlist Spotify"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat."
          image="https://i.scdn.co/image/ab67706c0000bebb4ad963c1de77cf85ac3f8962"
          buttomText="Ver mais"
          buttomLink="https://br.lipsum.com/"
        /> */}
      </Div>
    </section>
  )
}
export default RockSection
