import React from 'react'
import PhotoGallery from './PhotoGallery/PhotoGallery'

const GallerySection = ({ title, photos }) => {
  return (
    <div className="max-w-screen-xl mx-auto p-4 pb-8">
      <h2 className="text-lg text-primary text-left ml-6 mb-5">{title}</h2>
      <PhotoGallery photos={photos} />
    </div>
  )
}

export default GallerySection
