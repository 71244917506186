import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

const BenefitItem = ({ title, description, image, cta = false }) => {
  return (
    <div
      className="flex-1 flex flex-col py-5 md:py-32 pl-5 pr-24 md:mr-5 mb-5 self-stretch rounded-lg banner"
      style={{
        background: `linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.95) 45%, rgba(0,0,0,0.7) 75%, rgba(0,0,0,0) 100%) ,url(${image})`
      }}
    >
      <h3 className="text-lg text-primary mb-8">{title}</h3>
      <p className="mreaves text-base text-white mb-4">{description}</p>
      {cta && (
        <a
          href={cta.to}
          target="_blank"
          className="text-base text-primary flex hover:underline"
          rel="noreferrer"
        >
          {cta.title}
          <FaArrowRight className="h-5 w-5 ml-2" />
        </a>
      )}
    </div>
  )
}
export default BenefitItem
