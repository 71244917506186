import React from 'react'
import Div from '../Motion/Div/Div'
import InternalButton from '../Button/InternalButton'

const OurHistory = ({ title, description, button }) => {
  return (
    <section className="bg-dark">
      <Div className="flex flex-col max-w-screen-xl mx-auto py-16 px-5 justify-center items-center">
        <h2 className="text-2xl text-primary mb-5 text-center">{title}</h2>
        <p className="mreaves text-base text-center text-white w-full md:w-1/2 mb-8">
          {description}
        </p>
        <div className="flex">
          <InternalButton link={`/${button.link}`} title={button.title} />
        </div>
      </Div>
    </section>
  )
}
export default OurHistory
