import React from 'react'
import { Link } from 'gatsby'

const InternalButton = ({ title, link, icon = false, className }) => {
  return (
    <Link
      className={`${className} flex items-center justify-center py-3 px-4 bg-primary rounded-full text-white text-base hover:bg-gray-300 hover:text-primary`}
      to={link}
    >
      {title}
      {icon}
    </Link>
  )
}
export default InternalButton
