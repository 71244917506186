import React from 'react'

import Div from '../Motion/Div/Div'
import BenefitItem from './BenefitItem'

const BenefitsSection = ({ cards }) => {
  return (
    <section className="bg-dark">
      <Div className="flex flex-col max-w-screen-xl mx-auto lg:flex-row py-10 px-5 items-center">
        {cards.map((card, index) => {
          if (card.isActive)
            return (
              <BenefitItem
                key={index}
                title={card.title}
                description={card.description}
                image={card.cardImage.childImageSharp.fluid.src}
                cta={card.cta}
              />
            )
        })}
        {/* <BenefitItem
          title="Espaço Kids"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat."
          image="https://www.brinquedosparaplayground.com.br/imagens/Playground-Kids-305.jpg"
        />
        <BenefitItem
          title="Ambiente Aconchegante"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat."
          image="https://www.leblog.com.br/wp-content/uploads/2018/08/sandoui-dest.jpg"
        />
        <BenefitItem
          title="Reservado para Eventos"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqaaua. Ut enim
          ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat."
          image="https://www.blogdocasamento.com.br/wp-content/uploads/casamento-na-praia-Giovana-e-Marcus8.jpg"
        /> */}
      </Div>
    </section>
  )
}
export default BenefitsSection
